.verify-code-input {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .input-container {
        display: flex;
        .code-input {
            display: flex;
            width: 72px;
            margin-left: 16px;
            background-color: #091429;
            border-radius: 8px;
            height: 90px;
            font-weight: bold;
            font-size: 48px;
            color: #FFFFFF;
            text-align: center;
            outline: none;
        }
    }
    .error-tips {
        margin-top: 32px;
        color: #D92A0F;
        font-size: 28px;
    }
    .desc {
        margin-top: 48px;
        font-size: 28px;
        font-weight: bold;
        .resend {
            color:#2568FC;
        }
    }
}
.ReactModalPortal {
    .ReactModal__Content {
        background-color: #282A3D!important;
        border: none!important;
        border-radius: 32px!important;
        .nc-container {
            display: flex;
            justify-content: center;
            .nc_wrapper .nc_scale .nc_bg {
                background-color: #2568FC;
                border-radius: 8px;
            }
        }
        .nc_1_nocaptcha, .nc_wrapper {
            border-radius: 8px;
        }
        .nc_scale {
            background-color: #21222C;
            border-radius: 8px;
            .btn_slide {
                color: #FFFFFF;
                font-size: 40px;
            }
            span {
                background-color: #2568FC;
                border: 2px solid #2568FC;
                border-radius: 8px;
            }
        }
    }
    .ReactModal__Overlay {
        z-index: 1001;
    }
}