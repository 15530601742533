.pay-result {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    &-content {
        flex: 1;
        background-color: #091429;
        color: #FFFFFF;
        .buzz-logo {
            margin: 100px 0 98px 0;
        }
        .success-tips {
            display: flex;
            flex-direction: column;
            align-items: center;
            .success-logo {
                width: 104px;
                height: 104px;
            }
            .desc {
                margin-top: 46px;
                font-weight: 700;
                font-size: 36px;
            }
        }
        .jump-tips {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            font-weight: 400;
            font-size: 28px;
            margin: 48px 0 72px 0;
        }
        .pay-result-detail {
            margin: 48px 32px;
            padding: 48px 32px;
            border: 2px solid #373949;
            border-radius: 8px;
            .result-item {
                &:last-child {
                    margin-bottom: 0
                }
                margin-bottom: 32px;
                display: flex;
                font-size: 28px;
                align-items: center;
                justify-content: space-between;
                .desc {
                    font-weight: 400;
                }
                .value {
                    font-weight: 700;
                    word-wrap: break-word;
                    word-break: break-all;
                    max-width: 350px;
                    text-align: right;
                    display: flex;
                    align-items: center;
                }
                .copy-img {
                    display: flex;
                    align-items: center;
                }
                img {
                    width: 23px;
                    height: 25px;
                    margin-left: 10px;
                }
            }
        }
        .go-back {
            width: 622px;
            height: 90px;
            line-height: 90px;
            text-align: center;
            font-weight: 500;
            margin: 48px auto;
            font-size: 28px;
            background: #2568FC;
            border-radius: 60px;
        }
    }
}