.deposit-pay-detail {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    .pay-detail-container {
        flex: 1;
        background-color: #091429;
        .buzz-logo {
            margin: 32px 0 48px 0;
        }
        .cancel-order {
            background: #2568FC;
            border-radius: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 32px 64px;
            height: 90px;
            color: #FFFFFF;
            font-weight: 500;
            font-size: 28px;
        }
        .order-detail {
            color: #FFFFFF;
            border: 2px solid #373949;
            border-radius: 8px;
            margin: 32px;
            padding: 32px;
            .pay-detail-list {
                margin-top: 16px;
            }
            .pay-detail-item {
                margin-bottom: 48px;
                font-size: 28px;
                .desc {
                    font-weight: 400;
                }
                .value {
                    font-weight: 700;
                    margin-top: 16px;
                    color: #2568FC;
                }
            }
            .network-select {
                .title {
                    color: #ADADAD;
                    margin-bottom: 32px;
                    font-weight: 400;
                    font-size: 28px;
                }
                .adm-dropdown {
                    border: 1px solid #5D5F70;
                    background-color: #091429;
                    border-radius: 4px;
                    font-weight: 700;
                    font-size: 16px;
                }
                .adm-dropdown-nav {
                    height: 48px;
                    border-bottom: none;
                    .adm-dropdown-item-title {
                        width: 100%;
                        justify-content: space-between;
                        padding: 0 16px;
                    }
                    .adm-dropdown-item-title-arrow {
                        font-size: 16px;
                        color: #FFFFFF;
                    }
                }
            }
            .qr-code-container {
                margin: 48px 0 32px 0;
                .qr-code-url {
                    padding: 0 20px;
                    margin-top: 28px;
                    height: 104px;
                    background: #31364B;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    #qr-code-val {
                        font-size: 24px;
                        overflow: scroll;
                        // 隐藏滚动条
                        scrollbar-width: none;
                        -ms-overflow-style: none;
                        &::-webkit-scrollbar {
                            display: none; /* Chrome Safari */
                        }
                    }
                    .qr-code-copy {
                        color: #2568FC;
                        font-size: 28px;
                        padding-left: 6px;
                    }
                }
            }
            .qr-code {
                display: flex;
                .qr-code-desc {
                    font-size: 28px;
                    margin-left: 32px;
                    .network {
                        line-height: 36px;
                    }
                    .value {
                        color: #2568FC;
                        font-weight: 700;
                    }
                }
                .desc-container {
                    margin-top: 20px;
                    .value {
                        margin-top: 16px;
                    }
                }
                #qr-code-img {
                    width: 212px;
                    height: 212px;
                }
            }
            .notice-tips {
                font-weight: 400;
                font-size: 24px;
                white-space: pre-line;
                .notice-item {
                    margin-bottom: 32px;
                }
            }
        }
        .adm-modal-wrap {
            max-width: 100%;
        }
        .cancel-order-confirm-modal {
            padding: 50px 40px 23px 40px;
            .adm-modal-close {
                right: 20px;
                top: 25px;
                color: #212529;
            }
            .adm-modal-title {
                font-weight: 900;
                font-size: 24px;
                color: #212529;
                text-align: left;
                padding: 0;
                margin: 0;
            }
            .adm-modal-content {
                width: 290px;
                padding: 16px 0 26px 0;
                font-weight: 400;
                font-size: 14px;
            }
            .adm-modal-footer {
                flex-direction: row;
                justify-content: space-between;
                padding: 0;
                .adm-space-item {
                    &:first-child .adm-modal-button {
                        background-color: #BDBDBD;
                    }
                    &:last-child .adm-modal-button {
                        background-color: #2568FC;
                    }
                    .adm-modal-button {
                        width: 132px;
                        height: 40px;
                        font-weight: 700;
                        font-size: 16px;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
}
.adm-dropdown-popup-body {
    width: 83%;
    margin: 0 32px;
    color: #FFFFFF;
    .adm-dropdown-item-content {
        background-color: #091429;
        .adm-radio-content {
            font-size: 14px;
        }
    }
}