.count-down {
    background: #FFECED;
    position: sticky;
    z-index: 1000;
    top: 148px;
    .desc {
        text-align: center;
        font-weight: 400;
        font-size: 24px;
        padding-top: 16px;
    }
    .time {
        text-align: center;
        font-weight: 700;
        font-size: 40px;
        padding: 20px 0;
    }
}