.user-info {
    height: 168px;
    margin: 32px;
    border: 2px solid #373949;
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    .head {
        margin-left: 32px;
        width: 72px;
        height: 72px;
    }
    .head-img {
        width: 72px;
        height: 72px;
        border-radius: 50%;
    }
    .info {
        margin-left: 28px;
        font-size: 28px;
    }
    .email, .phone {
        line-height: 28px;
    }
    .phone {
        margin-top: 12px;
    }
}