.phone-number-selector-wrapper {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #373949;
    border-radius: 8px;
    margin: 32px;
    padding: 34px 64px;
    .phone-number-selector {
        display: flex;
        align-items: center;
        height: 90px;
        width: 100%;
        border: 2px solid #5D5F70;
        border-radius: 8px;
        .r-marginRight-zso239, .r-zso239 {
            margin-left: 18px;
            margin-right: 0;
        }
        .css-text-901oao, .css-901oao {
            font-size: 60px;
            display: inline-block;
            height: 90px;
            line-height: 90px;
        }
        .calling-code {
            font-size: 28px;
            margin: 0 14px;
        }
        .number-input {
            padding-left: 32px;
            height: 50px;
            border-radius: 0;
            border: none;
            border-left: 2px solid #5D5F70;
            font-size: 28px;
            color: #FFFFFF;
            outline:none;
            width: 200px;
            background-color: #091429;
        }
    }
    .title {
        font-size: 40px;
    }
    .desc {
        padding: 32px 0;
        font-size: 28px;
    }
    .login-btn {
        width: 100%;
        height: 90px;
        border-radius: 60px;
        background: #2568FC;
        margin-top: 32px;
        display: flex;
        font-size: 28px;
        justify-content: center;
        align-items: center;
        img {
            margin: 5px 0 0 10px;
            width: 150px;
            height: 28px;
        }
    }
}
.iti-container {
    height: 500px;
    top: 636px!important;
}
.css-view-1dbjc4n, .css-1dbjc4n {
    .r-outlineColor-m4mzzj, .r-m4mzzj {
        outline: none;
    }
    .r-justifyContent-1wtj0ep, .r-1wtj0ep {
        .r-marginRight-zso239, .r-zso239 {
            margin: 0 24px!important;
        }
        .r-transform-1sncvnh, .r-1sncvnh {
            &:first-child {
                flex: 1!important;
            }
            &:last-child {
                flex-grow: 0;
                width: 100px;
            }

        }
    }
}