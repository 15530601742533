.vibra-header {
    position: sticky;
    background-color: #FFFFFF;
    z-index: 1000;
    top: -1px;
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    header {
        clear: both;
        height: 150px;
        line-height: 150px;
        width: 100%;
        position: relative;
        padding: 0 24px;
        .back {
            float: left;
            display: flex;
            font-size: 28px;
            justify-content: center;
            align-items: center;
        }
        .title {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            height: 100%;
            align-items: center;
            img {
                width: 282px;
                height: 52px;
            }
        }
        .right {
            float: right;
        }
        .back-icon {
            font-size: 28px;
            margin-right: 4px;
        }
        .download {
            width: 166px;
            font-size: 24px;
            height: 48px;
            border-radius: 48px;
            color: #FFFFFF;
            background: #2568FC;
            padding: 0;
        }
    }
}
