#pay-detail {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    .pay-detail-container {
        flex: 1;
        background-color: #091429;
    }
    .adm-modal-wrap {
        max-width: 100%;
    }
    .adm-modal-body {
        background-color: #282A3D;
        padding: 30px 20px;
        border-radius: 16px;
        .adm-modal-content {
            padding: 0;
        }
        .adm-space {
            height: 0;
        }
        .adm-modal-close  {
            font-size: 20px;
            top: 30px;
            right: 20px;
            color: #FFFFFF;
            font-weight: 900;
        }
        .pay-check-modal {
            color: #FFFFFF;
            .title {
                font-size: 24px;
                font-weight: 900;
            }
            .desc {
                font-weight: 400;
                font-size: 14px;
                margin-top: 20px;
            }
            .code-input {
                background-color:#282A3D;
            }
        }
    }
}