#vibra-verify-code {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    .verify-code-container {
        flex: 1;
        background-color: #091429;
        .verify-code-card {
            margin: 32px;
            border: 2px solid #373949;
            border-radius: 8px;
            color: #FFFFFF;
            padding: 32px 72px 48px 72px;
            .title {
                text-align: center;
                font-weight: bold;
                font-size: 40px;
                margin-bottom: 32px;
            }
            .sub-title {
                text-align: center;
                font-size: 28px;
            }
        }
    }
}
.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, .6)!important
}