.pay-card-container {
    margin: 32px;
    padding: 0 32px;
    border: 2px solid #373949;
    border-radius: 8px;
    color: #FFFFFF;
    .title {
        font-size:28px;
        color: #ADADAD;
        margin: 48px 0;
    }
    .adm-radio-icon {
        width: 15px;
        height: 15px;
    }
    .pay-method {
        margin-bottom: 56px;
        .balance-tips {
            height: 56px;
            line-height: 56px;
            margin-left: 58px;
            color: #D92A0F;
            font-size: 28px;
        }
        .pay-item {
            display: flex;
            justify-content: space-between;
            .adm-radio-content {
                width: 100%;
                font-size: 14px;
                background: #2568FC;
                border-radius: 4px;
                margin-left: 13px;
                padding: 11px 0 11px 12px;
            }
            .pay-title {
                font-weight: bold;
            }
            .pay-desc {
                margin-top: 8px;
            }
        }
    }
    .pay-mount-card {
        .pay-list {
            margin: 48px 0;
        }
        .pay-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 26px;
            flex-wrap: wrap;
            margin-bottom: 32px;
            .value {
                font-weight: bold;
                word-wrap: break-word;
                word-break: break-all;
                max-width: 350px;
                text-align: right;
                display: flex;
                align-items: center;
            }
            .copy-img {
                display: flex;
                align-items: center;
            }
            img {
                width: 23px;
                height: 25px;
                margin-left: 10px;
            }
        }
        .total-container {
            display: flex;
            font-size: 28px;
            justify-content: space-between;
            border-top: 2px solid #373949;
            padding: 48px 0;
        }
    }
    .agreement-wrapper {
        display: flex;
        margin-bottom: 48px;
        align-items: center;
        .agreement-radio {
            .adm-radio-content {
                font-size: 12px;
            }
        }
        .agreement {
            color: #2568FC;
            margin-left: 10px;
            font-size: 20px;
            margin-top: 4px;
        }
    }
    .pay-btn {
        background: #2568FC;
        border-radius: 60px;
        width: 100%;
        padding: 30px 0;
        border: none;
        font-weight: 500;
        font-size: 28px;
        color: #FFFFFF;
        margin-bottom: 48px;
        &-disable {
            background-color:#404040;
            color: #6D6D6D;
        }
    }
}