* {
    padding: 0;
    margin: 0;
}
html, body {
    height: 100%;
    width: 100%;
}
#root {
    height: 100%;
    min-height: 100%;
}

.adm-auto-center-content {
    // toast 保证单词完整性
    word-break: break-word;
}

#global-loading {
    position: fixed;
    z-index: 1001;
    width: 100%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.5);
}
// ios图片base64不展示的问题 —— vw兼容性适配导致
// https://blog.csdn.net/u014477038/article/details/97175573
img {
    content: normal !important
}
// input自动填充验证码的样式修正 —— 偏黄色的focus
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
    -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
}